export const languages = [
  { value: 'af', name: 'Afrikaans' },
  { value: 'sq', name: 'Albanian' },
  { value: 'ar', name: 'Arabic' },
  { value: 'hy', name: 'Armenian' },
  { value: 'az', name: 'Azeri' },
  { value: 'be', name: 'Belarusan' },
  { value: 'bn', name: 'Bengali' },
  { value: 'bs', name: 'Bosnian' },
  { value: 'bg', name: 'Bulgarian' },
  { value: 'my', name: 'Burmese' },
  { value: 'km', name: 'Cambodian' },
  { value: 'ce', name: 'Cantonese' },
  { value: 'ca', name: 'Catalan' },
  { value: 'zh', name: 'Chinese' },
  { value: 'chi', name: 'Chinese - Simplified' },
  { value: 'zho', name: 'Chinese - Traditional' },
  { value: 'cr', name: 'Croatian' },
  { value: 'cs', name: 'Czech' },
  { value: 'da', name: 'Danish' },
  { value: 'nl', name: 'Dutch' },
  { value: 'en', name: 'English' },
  { value: 'et', name: 'Estonian' },
  { value: 'fs', name: 'Farsi' },
  { value: 'fi', name: 'Finnish' },
  { value: 'fr', name: 'French' },
  { value: 'ka', name: 'Georgian' },
  { value: 'de', name: 'German' },
  { value: 'el', name: 'Greek' },
  { value: 'gu', name: 'Gujarati' },
  { value: 'he', name: 'Hebrew' },
  { value: 'hi', name: 'Hindi' },
  { value: 'hu', name: 'Hungarian' },
  { value: 'is', name: 'Icelandic' },
  { value: 'id', name: 'Indonesian' },
  { value: 'it', name: 'Italian' },
  { value: 'ja', name: 'Japanese' },
  { value: 'kn', name: 'Kannada' },
  { value: 'rw', name: 'Kinyarwanda' },
  { value: 'ko', name: 'Korean' },
  { value: 'ku', name: 'Kurdish' },
  { value: 'lo', name: 'Lao' },
  { value: 'la', name: 'Latin' },
  { value: 'lt', name: 'Lithuanian' },
  { value: 'mk', name: 'Macedonian' },
  { value: 'ms', name: 'Malay' },
  { value: 'ml', name: 'Malayalam' },
  { value: 'ma', name: 'Mandarin' },
  { value: 'mr', name: 'Marathi' },
  { value: 'ne', name: 'Nepali' },
  { value: 'no', name: 'Norwegian' },
  { value: 'ps', name: 'Pashtu' },
  { value: 'fa', name: 'Persian' },
  { value: 'pl', name: 'Polish' },
  { value: 'pt', name: 'Portuguese' },
  { value: 'pa', name: 'Punjabi' },
  { value: 'ro', name: 'Romanian' },
  { value: 'ru', name: 'Russian' },
  { value: 'sr', name: 'Serbian' },
  { value: 'sk', name: 'Slovak' },
  { value: 'sl', name: 'Slovene' },
  { value: 'so', name: 'Somali' },
  { value: 'es', name: 'Spanish' },
  { value: 'sv', name: 'Swedish' },
  { value: 'tl', name: 'Tagalog' },
  { value: 'ta', name: 'Tamil' },
  { value: 'te', name: 'Telugu' },
  { value: 'th', name: 'Thai' },
  { value: 'ti', name: 'Tigrinya' },
  { value: 'tr', name: 'Turkish' },
  { value: 'uk', name: 'Ukrainian' },
  { value: 'ur', name: 'Urdu' },
  { value: 'vi', name: 'Vietnamese' }
];