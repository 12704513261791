import * as React from 'react';
import { useDropzone } from 'react-dropzone'
import { client } from './remote';

interface IUploaderProps {
}

interface Uploader {
  basic?: boolean;
  label?: string;
  onUpload: (any) => void;
  endpoint?: string;
  tenantId?: string;
  // order: any;
  // orderItem: string;
  // uploadSlot: any;
}

const { useEffect, useState, useCallback, useRef } = React

export const Uploader: React.FunctionComponent<Uploader> = (props) => {
  const { label, basic = false } = props;
  // const { sequence } = uploadSlot;
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileUploaded, setFileUploaded] = useState({}) as any;
  const [barValue, setBarValue] = useState(5)

  const endpoint = props.endpoint ? props.endpoint : '/sfapi/uploads-misc'

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }
    setIsUploaded(false)

    setFiles(files.concat(acceptedFiles));
    const uploadedFiles = acceptedFiles.map(async f => {
      let formData = new FormData();
      formData.append('file', f);
      if (props.tenantId) {
        formData.append('tenantId', props.tenantId);
      }

      // formData.append(`sequence[${sequence}]`, acceptedFiles[0]);

      // formData.set('orderItemId', orderItem);

      try {
        setIsSubmitting(true)
        setErrorMessage('')
        const res: any = await client(endpoint, {
          method: 'POST',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)

            if (percent < 100) {
              if (percent < 5) {
                percent = 5;
              }

              setBarValue(percent)
            }
          }
        })


        if (res) {
          setTimeout(() => {
            setBarValue(100)
          }, 50)

          if (props.endpoint) {
            props.onUpload && props.onUpload(res.data);
          }

          props.onUpload && props.onUpload(res.data && res.data.files);
        }
      } catch (error) {
        setIsUploaded(false)
        setIsSubmitting(false)
        setErrorMessage('Upload fail. Please try again')
      }
    });

    Promise.all(uploadedFiles).then(res => {
      setIsSubmitting(false)
      setIsUploaded(true)
      setTimeout(() => {
        setIsUploaded(false)
      }, 2000)
    })

  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const rootProps = getRootProps() as any;

  let src = null;
  let filename = null;

  const wasUploaded = isUploaded;
  // const cardHeaderStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '280px' }

  return (
    <>
      {
        basic ? <div  {...rootProps} className="basic">
          <div className="basic-inner">
            {props.children}
            <input {...getInputProps({ multiple: true }) as any} />
          </div>
        </div> : <div className="card card-dotted uploader">
            <div className="" >

              <div className="">
                {/* <div className="card-header" style={cardHeaderStyle}>
            <ArtworkUploadImage src={src} showPlaceholder={true} filename={filename} justUploaded={isUploaded} />
          </div> */}
                <div className="">

                  {/* {
                fileUploaded && fileUploaded.originalFilename && <>
                  {fileUploaded.originalFilename}
                </>
              } */}

                  {
                    <div {...rootProps} className="py-5 text-center">
                      {
                        label ? label : <>
                          <div className="pb-2"><strong><i className="fas fa-cloud-upload-alt fa-3x"></i></strong></div>
                      Drag and drop file(s) here <br />or<br /><br /> <span className="browse-label py-1 px-3">BROWSE</span>
                        </>
                      }
                      <input {...getInputProps({ multiple: true }) as any} />

                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
      }
      {isSubmitting && <UploadSlotProgressBar barValue={barValue} />}
      {(isUploaded && !errorMessage) && <ArtworkUploadMessage type='success' value="Upload complete." />}
      {errorMessage && <ArtworkUploadMessage type='error' value={errorMessage} />}
    </>
  );
}


export interface IUploadSlotProgressBarProps {
  barValue: number;
}

export const UploadSlotProgressBar: React.FunctionComponent<IUploadSlotProgressBarProps> = (props) => {
  const { barValue } = props;
  return (
    <div className="progress mt-3">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow={barValue}
        aria-valuemin={6}
        aria-valuemax={100}
        style={{ width: `${barValue}%` }}>
        <span className='sr-only'>{barValue}% complete</span>
      </div>
    </div>
  );
};


interface IArtworkUploadMessageProps {
  type: 'success' | 'error';
  value: string;
}

export const ArtworkUploadMessage: React.FunctionComponent<IArtworkUploadMessageProps> = (props) => {
  const { type, value } = props;
  const className = type === 'success' ? 'alert-success' : 'alert-danger';

  return (
    <div className={`alert ${className} mt-3`} role="alert">
      {value}
    </div>
  );
};

export default ArtworkUploadMessage;


interface IArtworkUploadImageProps {
  src?: string;
  filename?: string;
  showPlaceholder: boolean;
  retryMax?: number;
  retryFactor?: number;
  retryInterval?: number;
  justUploaded?: boolean;
}

export const ArtworkUploadImage: React.FunctionComponent<IArtworkUploadImageProps> = (props) => {
  const { src, filename, showPlaceholder = true, retryMax = 10, retryFactor = 1.15, retryInterval = 2000 } = props;
  const [isGenerating, setIsGenerating] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [isImageFail, setIsImageFail] = useState(false);

  const timeoutRef = useRef(null);

  useEffect(() => {
    // if certain props, reset the retrying
    setIsImageFail(false);
    setRetryCount(0);
    setIsGenerating(true);
    // When unmounting, clear any timeouts
    return () => timeoutRef && clearTimeout(timeoutRef.current);
  }, [src, filename, showPlaceholder]);
  if (showPlaceholder) {
    return <i className="fas fa-cloud-upload-alt"></i>
  }

  const retrySrc = `${src}?v=${retryCount}`;
  const hiddenStyle = { width: 0, height: 0 };
  return (
    <div>
      <img
        style={isImageFail ? hiddenStyle : { maxHeight: '220px' }}
        key={retrySrc}
        className={'card-img-top'}
        src={retrySrc}
        onError={() => {
          if (retryCount < retryMax) {
            setIsImageFail(true);
            timeoutRef.current = setTimeout(() => setRetryCount(v => v + 1), (1 + retryCount) * retryFactor * retryInterval);
          }
        }}
        onLoad={() => {
          if (timeoutRef) {
            clearTimeout(timeoutRef.current);
          }
          setIsImageFail(false);
          setIsGenerating(false)
        }}
      />
      {isImageFail && <i className={'far fa-image  fa-4x'}></i>}
      <div>{filename} {isGenerating && props.justUploaded && <span> Thumbnail is being generated...</span>}</div>
    </div>

  )
};

const Placeholder = () => <i className={'far fa-image  fa-4x'}></i>;

