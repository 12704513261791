import * as React from 'react';
import { render } from 'react-dom';

declare var $;
declare var ICWidgets;

$(document).ready(() => {
  const { AddToCart } = require('./AddToCart');
  const { GetAQuote } = require('./GetAQuote');
  const { Draft } = require('./Draft');

  ICWidgets.Client.on('init-complete', ({ React2, render22 }) => {
    console.log('got init complete from IC Widgets');
    const templateElement = document.getElementById('lf-add-to-cart');
    templateElement && render(
      <AddToCart />,
      templateElement)

    const templateElement2 = document.getElementById('lf-get-a-quote-translation');
    templateElement2 && render(
      <GetAQuote type="translation" key="translation" />,
      templateElement2)

    const templateElement3 = document.getElementById('lf-get-a-quote-interpreting');
    templateElement3 && render(
      <GetAQuote type="interpreting" key="interpreting" />,
      templateElement3)


    const templateElement4 = document.getElementById('lf-draft');
    templateElement4 && render(
      <Draft />,
      templateElement4)
  })
});
