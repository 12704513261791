import * as React from "react";
import Select from 'react-select';
import { Uploader } from './../AddToCart/Uploader';
import { getAuthToken, getAQuote, getUser } from '../remote';
import { languages } from '../AddToCart/languages';
import { translation, interpreting } from './categories'
import { countries, caProvinces, usStates } from './CountryStateProvince'

import styled from '@emotion/styled';
import ClipLoader from "react-spinners/ClipLoader";


const StyledClipLoader = styled(ClipLoader)`
  margin: auto
  position: absolute;
`;

const StyledButton = styled.button`
  position: relative;
`;

interface IGetAQuote {
  type: string;
}


declare global {
  interface Window {
    __initialState: any;
  }
}

const { useState, useEffect } = React

const initialValue = {
  name: '',
  email: '',
  phone: '',
  organizationName: '',
  description: '',
  password: ''
}

const initialShippingValue = {
  name: '',
  organizationName: '',
  countryCode: {
    label: 'United States of America',
    value: 'US'
  },
  address1: '',
  address2: '',
  city: '',
  stateProvince: {
    label: '',
    value: ''
  },
  postalCode: ''
}

const quantityOptions = []

for (let i = 1; i <= 10; i++) {
  quantityOptions.push({ value: i, label: i })
}

const shippingOptions = [
  { value: 'SHIPPING_SHIPOUT_AND_DIGITAL_DELIVERY', label: 'Shipping + Digital Delivery' },
  { value: 'SHIPPING_DIGITAL_DELIVERY', label: 'Digital Delivery Only ' },
]

export const GetAQuote: React.FunctionComponent<IGetAQuote> = (props: any) => {
  const { type } = props
  const [values, setValues] = useState(initialValue)
  const [langState, setLangState] = useState({ srcLangs: [], destLangs: [] });
  const [srcLang, setSrcLang] = useState(null);
  const [destLang, setDestLang] = useState(null);
  const [category, setCategory] = useState(null);
  const [po, setPO] = useState(null);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);

  const [shipping, setShipping] = useState(initialShippingValue)

  const tenantId = (window as any).__initialState?.tenant?._id ?? ''

  const [files, setFiles] = useState([]);
  const [user, setUser] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')
  const [responseMessage, setResponseMessage] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoadingUser, setIsLoadingUser] = useState(false)

  const handleChange = (e) => {
    setValues({
      ...values, [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    const srcLangs = languages.map(l => ({ value: l.value, label: l.name }));
    const destLangs = languages.map(l => ({ value: l.value, label: l.name }));
    setLangState({
      srcLangs,
      destLangs,
    });

    const loadUser = async () => {
      setIsLoadingUser(true)
      try {
        const res = await getUser()
        setIsLoadingUser(false)
        setUser(res.data)

        if (res.data) {
          setShipping({
            name: `${res.data.firstName ?? ''} ${res.data.lastName ?? ''}`,
            organizationName: res.data.organizationName ?? '',
            address1: res.data.address1 ?? '',
            address2: res.data.address2 ?? '',
            city: res.data.city ?? '',
            postalCode: res.data.postalCode ?? '',
            countryCode: { label: res.data?.countryCode || 'United States', value: res.data?.countryCode || 'US' },
            stateProvince: { label: '', value: res.data?.stateProvince || 'CA' }
          })
        }
      } catch (e) {
        setIsLoadingUser(false)
      }
    }


    loadUser()

  }, [])

  if (isLoadingUser) {
    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleShippingChange = (e) => {
    setShipping({
      ...shipping, [e.target.name]: e.target.value
    })
  }

  const handleShippingCountry = (value) => {
    setShipping({
      ...shipping, countryCode: value
    })
  }

  const handleShippingState = (value) => {
    setShipping({
      ...shipping, stateProvince: value
    })
  }

  const handleSourceChange = (value) => {
    setSrcLang(value);
  }

  const handleDestChange = (value) => {
    setDestLang(value);
  }

  const handleCategory = (value) => {
    setCategory(value)
  }

  const handleShippingMethod = (value) => {
    setSelectedShippingMethod(value)
  }

  const handleFilesAttached = (value) => {
    setFiles(files => files.concat(value));
  }

  const handleRemoveFile = (id) => {
    const filtered = files && files.length > 0 && files.filter(f => f.uploadLog !== id)

    setFiles(filtered);
  }

  const handleSubmit = () => {
    setResponseMessage('')
    setIsSubmitting(true)
    setErrorMessage('')

    const uploadLogs = files && files.length > 0 && files.reduce((files, f) => {
      if (f) {
        files.push(f.uploadLog)
      }

      return files
    }, [])

    const isCountryUSCandada = shipping?.countryCode?.value === 'US' || shipping?.countryCode?.value === 'CA'


    const payload = {
      customer: user?._id ?? '',
      selectedShippingMethod: selectedShippingMethod.value,
      po,
      shipTo: {
        name: shipping?.name ?? '',
        address1: shipping?.address1 ?? '',
        address2: shipping?.address2 ?? '',
        city: shipping?.city ?? '',
        stateProvince: isCountryUSCandada ? (shipping?.stateProvince?.value ?? '') : (shipping as any).state,
        postalCode: shipping?.postalCode ?? '',
        countryCode: shipping?.countryCode?.value ?? 'US'
      },
      items: [{
        type: 'others',
        attributes: [],
        quantity: 1,
        additionalInfo: values.description,
        miscUploads: uploadLogs,
        metadata: [
          {
            key: 'tr_type',
            value: type,
          },
          {
            key: 'tr_category',
            value: category.label,
          },
          {
            key: 'tr_source_name',
            value: srcLang.label,
          },
          {
            key: 'tr_dest_name',
            value: destLang.label,
          },
        ]
      }]
    }

    let signupData

    if (!user) {
      signupData = {
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone,
        organizationName: values.organizationName,
      }
    }


    return getAQuote(payload, signupData)
      .then(res => {

        setIsSubmitting(false)
        if (res.status === 201) {
          setResponseMessage('Submitted successfully.')

          setValues(initialValue)
          setSrcLang(null)
          setDestLang(null)
          setCategory(null)
          setSelectedShippingMethod(null)
          setFiles([])
        } else {
          setErrorMessage(res.message)
        }
      })
      .catch(e => {
        setIsSubmitting(false)
        const err = e && e.response.data

        if (err.code && err.code < 500) {
          setErrorMessage(err.message)
        } else {
          setErrorMessage('We are experiencing technical difficulties. Please try again')
        }
      })
  }

  const requiredField = !user ? [values.name, values.email, values.password, category, selectedShippingMethod, srcLang, destLang] : [category, selectedShippingMethod, srcLang, destLang, files]
  const isDisabled = !requiredField.reduce((acc, field) => acc && (Array.isArray(field) ? field?.length !== 0 : field !== null && field !== ""), true)


  const categories = type === 'translation' ? translation : interpreting

  const isStateProvince = shipping.countryCode.value === 'US' ? usStates : caProvinces

  return (
    <form autoComplete="new-password">
      {!user && <> <div className="form-group ">
        <div className="input-w-icon">
          <label className="">
            <i className="fa fa-user ml-2"></i>
          </label>
          <input
            autoComplete="new-password"
            type="text"
            name="name"
            className="form-control"
            placeholder="Your Name"
            value={values.name}
            onChange={handleChange} />
        </div>
      </div>
        <div className="form-group">
          <div className="input-w-icon">
            <label className="">
              <i className="fa fa-envelope ml-2"></i>
            </label>
            <input
              autoComplete="new-password"
              type="email"
              name="email"
              className="form-control"
              placeholder="Your Email"
              value={values.email}
              onChange={handleChange} />
          </div>
        </div>
        <div className="form-group">
          <div className="input-w-icon">
            <label className="">
              <i className="fa fa-lock ml-2"></i>
            </label>
            <input
              autoComplete="new-password"
              type="password"
              name="password"
              className="form-control"
              placeholder="Your Password"
              value={values.password}
              onChange={handleChange} />
          </div>
          <small id="emailHelp" className="form-text text-muted">A password is required to retrieve your custom quote.</small>
        </div>
        <div className="form-group">
          <div className="input-w-icon">
            <label className="">
              <i className="fa fa-phone ml-2"></i>
            </label>
            <input
              autoComplete="telephone"
              type="text"
              name="phone"
              className="form-control"
              placeholder="Your Telephone Number"
              value={values.phone} onChange={handleChange} />
          </div>
        </div>
        <div className="form-group">
          <div className="input-w-icon">
            <label className="">
              <i className="fa fa-briefcase ml-2"></i>
            </label>
            <input
              autoComplete="new-password"
              type="text"
              name="organizationName"
              className="form-control"
              placeholder="Your Company"
              value={values.organizationName} onChange={handleChange} />
          </div>
        </div> </>}
      <div className="form-group">
        <Select
          isSearchable
          options={categories}
          value={category}
          onChange={handleCategory}
          placeholder={'Select Category'}
        />
      </div>
      <div className="form-group">
        <Select
          isSearchable
          options={shippingOptions}
          value={selectedShippingMethod}
          onChange={handleShippingMethod}
          placeholder={'Select Shipping Method'}
        />
        {
          selectedShippingMethod && selectedShippingMethod.value !== 'SHIPPING_DIGITAL_DELIVERY' &&
          <small id="emailHelp" className="form-text text-muted">An address is required to provide accurate shipping options.</small>
        }
      </div>
      {
        selectedShippingMethod && selectedShippingMethod.value !== 'SHIPPING_DIGITAL_DELIVERY' && <>
          <div className="form-group">
            <div className="">
              <input
                autoComplete="new-password"
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                value={shipping.name}
                onChange={handleShippingChange} />
            </div>
          </div>

          <div className="form-group">
            <div className="">
              <input
                autoComplete="new-password"
                type="text"
                name="organizationName"
                className="form-control"
                placeholder="Business/Firm Name"
                value={shipping.organizationName}
                onChange={handleShippingChange} />
            </div>
          </div>

          <div className="form-group">
            <Select
              isSearchable
              options={countries}
              value={countries.filter((option) => option.value === shipping.countryCode.value)}
              onChange={handleShippingCountry}
              placeholder={'Select Country'}
            />
          </div>

          <div className="form-group">
            <div className="">
              <input
                autoComplete="new-password"
                type="text"
                name="address1"
                className="form-control"
                placeholder="Address 1"
                value={shipping.address1}
                onChange={handleShippingChange} />
            </div>
          </div>

          <div className="form-group">
            <div className="">
              <input
                autoComplete="new-password"
                type="text"
                name="address2"
                className="form-control"
                placeholder="Address 2"
                value={shipping.address2}
                onChange={handleShippingChange} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-12 col-md">
              <div className="">
                <input
                  autoComplete="new-password"
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City"
                  value={shipping.city}
                  onChange={handleShippingChange} />
              </div>
            </div>

            <div className="form-group col-12 col-md">
              <div className="">
                {
                  (shipping?.countryCode?.value === 'US' || shipping?.countryCode?.value === 'CA') ? <>
                    <Select
                      isSearchable
                      options={shipping.countryCode.value === 'US' ? usStates : caProvinces}
                      value={isStateProvince.filter((option) => option.value === shipping.stateProvince.value)}
                      onChange={handleShippingState}
                      placeholder={'Select State/Province'}
                    />
                  </> :
                    <input
                      autoComplete="new-password"
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder="State/Province"
                      defaultValue={shipping.stateProvince.value}
                      onChange={handleShippingChange} />
                }
              </div>
            </div>

            <div className="form-group col-12 col-md">
              <div className="">
                <input
                  autoComplete="new-password"
                  type="text"
                  name="postalCode"
                  className="form-control"
                  placeholder="Postal Code"
                  value={shipping.postalCode}
                  onChange={handleShippingChange} />
              </div>
            </div>
          </div>


        </>
      }
      <div className="form-group">
        <div className="input-w-icon">
          <label className="label-textarea">
            <i className="fa fa-folder-open ml-2"></i>
          </label>
          <textarea
            name="description"
            rows={10}
            className="form-control"
            placeholder="Project Description"
            value={values.description}
            onChange={handleChange}></textarea>
        </div>
      </div>
      <div className="form-group">
        <Select
          isSearchable
          options={langState.srcLangs}
          value={srcLang}
          onChange={handleSourceChange}
          placeholder={'Source language'}
        />
      </div>
      <div className="form-group">
        <Select
          isSearchable
          options={langState.destLangs}
          value={destLang}
          onChange={handleDestChange}
          placeholder={'Destination language'}
        />
      </div>
      <div className="form-group">
        <input
          autoComplete="po"
          type="text"
          name="po"
          className="form-control"
          placeholder="PO"
          value={po} onChange={(e) => setPO(e.target.value)} />
      </div>

      <div className="form-group">
        <Uploader
          onUpload={(v) => {

            if (v.files && v.files[0]) {
              const f = v.files[0];
              handleFilesAttached(f)
            }
          }}

        />
        {
          files?.map((f: any, index: number) => (
            <button key={index} type="button" className="btn btn-light mt-2 mr-1 shadow-none" >
              {f.originalFilename}
              <span
                className="badge badge-light ml-1" onClick={() => handleRemoveFile(f.uploadLog)}>
                <i className="fas fa-times" ></i>
              </span></button>
          )) ?? null
        }
      </div>
      {
        responseMessage && <div className="alert alert-success mt-3">{responseMessage}</div>
      }
      {
        errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>
      }
      <div className=" justify-content-center text-center">
        <StyledButton
          className="btn btn-primary"
          disabled={isSubmitting || isDisabled} onClick={handleSubmit}>
          {isSubmitting && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                textAlign: 'center',
                top: 0,
                left: 0,
              }}
            >
              <StyledClipLoader
                size={10}
                color={'#123abc'}
                loading={isSubmitting || isDisabled}
              />
            </div>
          )}
          SUBMIT
      </StyledButton>
      </div>
    </form>
  )
}

export default GetAQuote