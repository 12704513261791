import * as React from "react"
import { Uploader } from './../AddToCart/Uploader'
interface IDraft {

}

export const Draft: React.FunctionComponent<IDraft> = props => {


  return (
    <>
      <div className="row ">
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-6 offset-md-6">
              <button
                type="button"
                className="btn">
                <i className="fas fa-cloud-download-alt"></i> Download All
              </button>
            </div>
          </div>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="btn btn-primary">
            Approve All Drafts
              </button>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-4">12345-1</div>
                <div className="col-8">
                  <div>Arabic &gt; Hebrew</div>
                  <div>Sean's Birth Certificate</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <button
                type="button"
                className="btn btn-primary">
                <i className="fas fa-cloud-download-alt"></i> Download
              </button>
            </div>

          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox1" value="option1" />
              <label className="form-check-label" htmlFor="inlineCheckbox1">Approve</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineCheckbox2" value="option2" />
              <label className="form-check-label" htmlFor="inlineCheckbox2">Send draft back for further review</label>
            </div>
          </div>

          <div className="form-group">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              placeholder="Comments..."
              rows={4} />
          </div>
          <Uploader
            basic
            onUpload={(v) => {


            }}

          >
            <button
              type="button"
              className="btn">
              <i className="fas fa-cloud-upload-alt"></i> Upload Comments
              </button>
          </Uploader>
        </div>
      </div>
      <hr />

      <div className="form-group">
        <textarea
          className="form-control"
          id="exampleFormControlTextarea1"
          placeholder="Comments..."
          rows={3} />
      </div>

      <div className="text-right">
        <button
          type="button"
          className="btn btn-primary">
          Submit
              </button>
      </div>
    </>
  )
}