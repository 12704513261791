import * as React from "react";
import Select from 'react-select';
import { Uploader } from './Uploader';
import { languages } from './languages';
import { getAuthToken, addtoCart } from '../remote';

import styled from '@emotion/styled';
import ClipLoader from "react-spinners/ClipLoader";

declare global {
  interface Window {
    __initialState: any;
  }
}

interface IAddToCartProps {
}

const StyledClipLoader = styled(ClipLoader)`
  margin: auto
  position: absolute;
`;

const StyledButton = styled.button`
  position: relative;
`;

const quantityOptions = []

for (let i = 1; i <= 10; i++) {
  quantityOptions.push({ value: i, label: i })
}

export const AddToCart: React.FunctionComponent<IAddToCartProps> = (props) => {
  const [page, setPage] = React.useState(null);
  const [langState, setLangState] = React.useState({ srcLangs: [], destLangs: [] });
  const [srcLang, setSrcLang] = React.useState(null);
  const [destLang, setDestLang] = React.useState(null);
  const [description, setDescription] = React.useState(null);

  const [isUploading, setIsUploading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [quantity, setQuantity] = React.useState(quantityOptions[0]);

  const [errorMessage, setErrorMessage] = React.useState('')

  React.useEffect(() => {
    const { page } = window && window.__initialState ? window.__initialState : { page: { product: '12345' } };
    if (!page) {
      throw Error('Page is missing from page context');
    }

    setPage(page);
    const srcLangs = languages.map(l => ({ value: l.value, label: l.name }));
    const destLangs = languages.map(l => ({ value: l.value, label: l.name }));
    setLangState({
      srcLangs,
      destLangs,
    });

  }, [])

  const handleSourceChange = (value) => {
    setSrcLang(value);
  }

  const handleDestChange = (value) => {
    setDestLang(value);
  }

  const handleUploadStatusChange = (value) => {
    console.log('got an upload status change', value);
    setIsUploading(value);
  }

  const handleFilesAttached = (value) => {
    // setFiles(files => [...files, ...value]);
    setFiles(files => files.concat(value));
  }

  const handleRemoveFile = (id) => {
    const filtered = files && files.length > 0 && files.filter(f => f.uploadLog !== id)

    setFiles(filtered);
  }

  const handleQuantity = (value) => {
    setQuantity(value)
  }

  const handleSubmit = () => {

    setIsSubmitting(true)

    const uploadLogs = files?.reduce((files, f) => {
      if (f) {
        files.push(f.uploadLog)
      }

      return files
    }, [])

    const { product } = page;

    return addtoCart({
      product: product._id,
      srcLang: srcLang.value,
      srcLangName: srcLang.label,
      destLang: destLang.value,
      destLangName: destLang.label,
      miscUploads: uploadLogs ?? [],
      quantity: quantity.value,
      description,
    }).then(res => {

      location.href = '/cart'
    }).catch(e => {
      setIsSubmitting(false)
      if (e.code && e.code < 500) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('We are experiencing technical difficulties. Please try again')
      }
    })
    // return axios.post('/sfapi/cart/add-item', {
    //   product: values.product,
    //   value: 1,
    //   addOns: values.addOns ? values.addOns : []
    // });

    // const request = axios.post(`http://localhost:3000${endpoint}`)
    // .timeout(1000 * 60 * 10)
    // .field('tenantId',process.env.TENANT)
    // .field('src_lang', '5a617d4255d96b452e922c3e')
    // .field('dest_lang', '5a617d4255d96b452e922c3e')
    // .field('product_id', '5b21955524f905692a0af9f1')
    // .field('quantity', 1)
    // .field('files', JSON.stringify(files));

    // request.then((result) => {
    //   console.log('yeahman');
    //   console.log(result.body);
    //   window.location.replace('/cart');
    // })
  }


  const isDisabled = !srcLang || !destLang
  return (
    <div>
      <h4 className="text-center">Upload your file(s)</h4>
      <div className="cf-wrap">
        <input
          type="hidden"
          name="product_id"
          value="5b21979124f9056f2a0afa01"
        />
        <div className="form-group">
          <Select
            isSearchable
            options={langState.srcLangs}
            value={srcLang}
            onChange={handleSourceChange}
            placeholder={'Source language'}
          />
        </div>
        <div className="form-group">
          <Select
            isSearchable
            options={langState.destLangs}
            value={destLang}
            onChange={handleDestChange}
            placeholder={'Destination language'}
          />
        </div>
        <div className="form-group">
          <div className="input-w-icon">
            <label className="label-textarea">
              <i className="fa fa-folder-open ml-2"></i>
            </label>
            <textarea
              name="description"
              rows={10}
              className="form-control"
              placeholder="Project Description/Comments"
              value={description}
              onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Uploader
              onUpload={(v) => {

                if (v.files && v.files[0]) {
                  const f = v.files[0];
                  handleFilesAttached(f)
                }
              }}

            // onUploadStatusChange={this.handleUploadStatusChange} 
            // onFilesAttached={this.handleFilesAttached} />
            />
            {
              files?.map((f: any, index: number) => (
                <button key={index} className="btn btn-light mt-2 mr-1 shadow-none" >
                  {f.originalFilename}
                  <span
                    className="badge badge-light ml-1" onClick={() => handleRemoveFile(f.uploadLog)}>
                    <i className="fas fa-times" ></i>
                  </span></button>
              )) ?? null
            }
          </div>
        </div>
        <input type="hidden" name="quantity" value="1" />
      </div>
      <div className="form-group">
        <label>Quantity</label>
        <Select
          isSearchable
          options={quantityOptions}
          value={quantity}
          onChange={handleQuantity}
          placeholder={'Quantity'}
        />
      </div>
      {
        errorMessage && <div className="alert alert-danger">{errorMessage}</div>
      }
      <div className="row">
        <div className="col-md-12">
          <div className="form-group mb-0 mt-3 text-center">
            <StyledButton
              className="btn btn-primary"
              disabled={isUploading || isDisabled || isSubmitting} onClick={handleSubmit}>
              {isSubmitting && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    textAlign: 'center',
                    top: 0,
                    left: 0,
                  }}
                >
                  <StyledClipLoader
                    // css={override}
                    size={10}
                    color={'#123abc'}
                    loading={isSubmitting}
                  />
                </div>
              )}
              ADD TO CART
      </StyledButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
