import axios from 'axios';
// const axios = require('axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token;

export async function getAuthToken() {
  const res = await axios.post('http://localhost:8032/authentication', {
    strategy: 'local',
    email: 'lf.adam@inkcloud9.com',
    password: 'abc123',
  }, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }
  });

  token = res.data.accessToken;
  return res.data;
}


interface AddToCart {
  product: string;
  srcLang: string;
  srcLangName: string;
  destLang: string;
  destLangName: string;
  miscUploads: any[];
  quantity: number;
  description?: string;
}

interface GetAQuote {
  selectedShippingMethod: string;
  shipTo: {};
  items: {
    type: string;
    attributes: any[];
    quantity: number;
    additionalInfo: string;
    miscUploads: any[];
    metadata: {
      key: string;
      value: any;
    }[];
  }[];
}

export async function addtoCart(data: AddToCart) {
  const { product, srcLang, srcLangName, destLang, miscUploads, destLangName, quantity, description } = data;

  const newQuantity = Math.floor(quantity)

  return axios.post('/sfapi/cart/add-item', {
    product,
    value: (!newQuantity || newQuantity < 1) ? 1 : newQuantity,
    miscUploads,
    description,
    metadata: [
      {
        key: 'tr_source_name',
        value: srcLangName,
      },
      {
        key: 'tr_source_id',
        value: srcLang,
      },
      {
        key: 'tr_dest_name',
        value: destLangName,
      },
      {
        key: 'tr_dest_id',
        value: destLang,
      },
    ]
  });

}

export async function getUser() {

  return axios.get('/sfapi/account-info')
}


export async function getAQuote(data: GetAQuote, signupData?: any) {

  if (signupData) {

    let name = signupData.name.split(" ")
    delete signupData.name

    try {
      const res = await axios.post('/sfapi/signup', {
        ...signupData,
        firstName: name[0],
        lastName: name[1] || '',
      })

      if (res.data.result !== undefined && res.data.result === false) {

        throw new Error(res.data.errorMessage)
      } else {

        const loginRes = await axios.post('/sfapi/login', {
          email: signupData.email,
          password: signupData.password,
        })

        if (loginRes) {

          const customRes = await axios.post('/sfapi/custom-quotes', {
            ...data,
            customer: res?.data?._id ?? ''
          })

          if (customRes) {
            setTimeout(() => {
              location.reload()
            }, 1500)

            return customRes
          }
        }
      }


    } catch (e) {
      return e
    }
  } else {
    return axios.post('/sfapi/custom-quotes', data);
  }


  // return axios.post('/sfapi/custom-quotes', data);

}