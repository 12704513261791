export const translation = [
  { value: 'certified-translation', label: 'Certified Translation' },
  { value: 'legal-translation', label: 'Legal Translation' },
  { value: 'business-translation', label: 'Business Translation' },
  { value: 'technical-translation', label: 'Technical Translation' },
  { value: 'financial-translation', label: 'Financial Translation' },
  { value: 'medical-healthcare-translation', label: 'Medical & Healthcare Translation' },
  { value: 'immigration-translation', label: 'Immigration Translation' },
  { value: 'academic-translation', label: 'Academic Translation' },
  { value: 'custom', label: 'Custom' },
]

export const interpreting = [
  { value: 'legal-interpreting', label: 'Legal Interpreting' },
  { value: 'conference-interpreting', label: 'Conference Interpreting' },
  { value: 'medical-interpreting', label: 'Medical Interpreting' },
  { value: 'consecutive-interpreting', label: 'Consecutive Interpreting' },
  { value: 'simultaneous-interpreting', label: 'Simultaneous Interpreting' },
  { value: 'custom', label: 'Custom' },
]
